import React, { useRef, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'

export default function ForgotPassword() {

    const emailRef = useRef();

    const { resetPassword, currentUser } = useAuth();

    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    if(currentUser) {
        history.push('/');
    }

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage('Check your inbox for further instructions');
            setLoading(false);
        } catch(error) {
            setError(error.message);
            setLoading(false);
        }
    }    

    return (
        <>
            <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <div className="w-100" style={{ maxWidth: "400px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center mb-3">Password Reset</h2>
                            { error && <div className="alert alert-danger">{ error }</div> }
                            { message && <div className="alert alert-success">{ message }</div>}
                            <form onSubmit={ handleSubmit }>
                                <div className="form-group mb-2" id="email">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" ref={emailRef} required></input>
                                </div>

                                <button disabled={ loading } type="submit" className="w-100 btn btn-primary">Reset Password</button>
                            </form>
                            <div className="w-100 text-center mt-3">
                                <Link to="/login">Login</Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 text-center mt-2">
                        Need an account? <Link to="/signup">Sign Up</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

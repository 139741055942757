import './App.css';

import Signup from './components/Signup';
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import Board from './components/Board'
import RelatedBoard from './components/RelatedBoard';

import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <PrivateRoute exact path="/" component={ Dashboard }/>
          <PrivateRoute path="/board/:board/related" component={ RelatedBoard } />
          <PrivateRoute path="/board/:board" component={ Board } />
          <Route path="/signup" component={ Signup } />
          <Route path="/login" component={ Login } />
          <Route path="/forgotpassword" component={ ForgotPassword } />
          
        </Switch>
    </AuthProvider>
    </Router>
  );
}

export default App;

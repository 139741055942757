import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { API_URL } from '../config/global';
const axios = require('axios');

export default function Dashboard() {

    const [loading, setLoading] = useState(true);
    const [boards, setBoards] = useState([]);
    const [error, setError] = useState('');
    const { logout, currentUser } = useAuth();
    const history = useHistory();

    useEffect(() => {
        currentUser.getIdToken(true).then(token => {
            axios.get(API_URL + '/board', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((data) => {
                setBoards(data.data.boards);
                setLoading(false);
            });
        });
    }, [currentUser]);

    async function handleLogout() {
        setError('');
        try {
            await logout();
            history.push('/login');
        } catch {
            setError('Failed to log out');
        }
    }

    if(loading) {
        return (
            <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <h1>Fetching Your Boards...</h1>
            </div>
        )
    } else {
        return (
            <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <div className="w-100" style={{ maxWidth: "400px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center mb-4">Boards</h2>
                            { error && <div className="alert alert-danger">{ error }</div> }
                            {
                                boards.map((board, key) => 
                                    <div key={key}>
                                        <Link to={`/board/${board}`}>{board}</Link>
                                        <br></br>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="w-100 text-center mt-2">
                        <button className="btn btn-link" onClick={ handleLogout }>Log Out</button>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { API_URL } from '../config/global';
import { useAuth } from '../contexts/AuthContext';
const axios = require('axios');

function RelatedBoard(props) {

    const board = props.match.params.board;
    
    const [videoId, setVideoId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [savingVideo, setSavingVideo] = useState(false);

    const { currentUser } = useAuth();

    useEffect(() => {

        currentUser.getIdToken(true).then((token) => {
            axios.get(API_URL + '/post/related', {
                params: {
                    board: board
                },
                headers: {
                    Authorization: `Bearer ${ token }`
                }
            }).then((res) => {
                setVideoId(res.data.videoId);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            });
        });
    }, [board, currentUser]);

    async function loadAnotherVideo() {
        setLoading(true);
        let newVideoId = await getRelatedVideoId();
        while(newVideoId === videoId) {
            newVideoId = await getRelatedVideoId();
        }
        setVideoId(newVideoId);
        setLoading(false);
    }

    function getRelatedVideoId() {
        return new Promise((resolve, reject) => {
            currentUser.getIdToken(true).then((token) => {
                axios.get(API_URL + '/post/related', {
                    params: {
                        board: board
                    },
                    headers: {
                        Authorization: `Bearer ${ token }`
                    }
                }).then((res) => {
                    resolve(res.data.videoId);
                }).catch((err) => {
                    reject(err);
                });
            });
        });
    }

    function saveVideo() {
        setSavingVideo(true);

        currentUser.getIdToken(true).then((token) => {
            axios.post(API_URL + '/post/save', {
                origin: 'youtube',
                postId: videoId,
                url: 'https://www.youtube.com/watch?v=' + videoId,
                board: board
            }, {
                headers: {
                    Authorization: `Bearer ${ token }`
                }
            }).then((res) => {
                // console.log(res);
                setSavingVideo(false);
            }).catch((err) => {
                console.log(err);
                setSavingVideo(false);
            });
        });
    }


    if(loading) {
        return (
            <>
                <h1 className="d-flex justify-content-center"><Link to="/">boards</Link>/<Link to={ "/board/" + board }>{board}</Link>/related</h1>
                <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "90vh" }}>
                    <h1>Loading Related Video...</h1>
                </div>
            </>
        )
    } else {
        return (
            <>
                <h1 className="d-flex justify-content-center"><Link to="/">boards</Link>/<Link to={ "/board/" + board }>{board}</Link>/related</h1>
                <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "90vh" }}>
                    <div>
                        { savingVideo && <div className="alert alert-warning">Saving video...</div> }
                        <h3 className="text-center">Based on your saved videos in board: { board }.</h3>
                        <YouTube videoId={ videoId } />
                        <div className="container d-flex justify-content-center btn-toolbar mx-2">
                            <button disabled={ savingVideo } onClick={ loadAnotherVideo } className="btn btn-primary">Another Video</button>
                            <button disabled={ savingVideo } onClick={ saveVideo } className="btn btn-primary">Save This Video</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default RelatedBoard

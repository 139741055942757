import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuth } from '../contexts/AuthContext'
import { API_URL } from '../config/global';

export default function useGetPosts(board, docId) {

    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [lastDocId, setLastDocId] = useState(null);

    useEffect(() => {

        setLoading(true);
        setError(false);

        currentUser.getIdToken(true).then(token => {
            axios.get(API_URL + `/board/posts?board=${board}&lastDocId=${docId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                res.data.posts.forEach((post) => {
                    setPosts((prevPosts) => [...prevPosts, post])
                });
                setHasMore(res.data.hasMore);
                setLastDocId(res.data.lastDocId);
                setLoading(false);
            }).catch((error) => {
                setError(true);
            });
        });
    }, [board, currentUser, docId]);

    return { loading, error, posts, hasMore, docId: lastDocId };
}

import React, { useRef, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'

export default function Signup() {

    const emailRef = useRef();
    const emailConfirmRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const { signup, currentUser } = useAuth();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    if(currentUser) {
        history.push('/');
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if(emailRef.current.value !== emailConfirmRef.current.value) {
            return setError('Emails do not match');
        }

        if(passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value);
            setLoading(false);
            history.push('/');
        } catch(error) {
            setError(error.message);
            setLoading(false);
        }
    }    

    return (
        <>
            <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <div className="w-100" style={{ maxWidth: "400px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center mb-3">Sign Up</h2>
                            { error && <div className="alert alert-danger">{ error }</div> }
                            <form onSubmit={ handleSubmit }>
                                <div className="form-group mb-2" id="email">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" ref={emailRef} required></input>
                                </div>

                                <div className="form-group mb-2" id="emailConfirm">
                                    <label htmlFor="emailConfirm">Confirm Email</label>
                                    <input type="email" className="form-control" ref={emailConfirmRef} required></input>
                                </div>

                                <div className="form-group mb-2" id="password">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" ref={passwordRef} required></input>
                                </div>

                                <div className="form-group mb-2" id="passwordConfirm">
                                    <label htmlFor="passwordConfirm">Confirm Password</label>
                                    <input type="password" className="form-control" ref={passwordConfirmRef} required></input>
                                </div>

                                <button disabled={ loading } type="submit" className="w-100 btn btn-primary">Sign Up</button>
                            </form>
                        </div>
                    </div>
                    <div className="w-100 text-center mt-2">
                        Already have an account? <Link to="/login">Log In</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

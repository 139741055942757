import React from 'react'

function SocialboardPost(props) {
    
    return (
        <div dangerouslySetInnerHTML={{ __html: props.post.oembed.html }} />
    )
}

export default SocialboardPost

import firebase from 'firebase/app'
import 'firebase/auth'

const app = firebase.initializeApp({
    apiKey: "AIzaSyDD2CTU9tbBR-HdPhR3ajBTUigzvrCMQmY",
    authDomain: "social-save-app.firebaseapp.com",
    projectId: "social-save-app",
    storageBucket: "social-save-app.appspot.com",
    messagingSenderId: "1022872494997",
    appId: "1:1022872494997:web:b117e42c366336b217cc9c",
    measurementId: "G-GWGLKKNG6L"
});

export const auth = app.auth();
export default app
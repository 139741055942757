import React, { useState, useRef, useCallback } from 'react'
import SocialboardPost from './SocialboardPost'
import { Link } from 'react-router-dom'
import useGetPosts from './useGetPosts'

function Board(props) {

    const board = props.match.params.board;
    const [lastDocId, setLastDocId] = useState(null);

    const {posts, hasMore, loading, error, docId} = useGetPosts(board, lastDocId);

    const observer = useRef(null);
    const lastPostElementRef = useCallback((node) => {
        if(loading) return;
        if(observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if(entries[0].isIntersecting && hasMore) {
                setLastDocId(docId);
            }
        }, { threshold: 1.0 } );
        if(node) observer.current.observe(node);
    }, [loading, hasMore, docId]);
    
    if(window.twttr.widgets) {
        window.twttr.widgets.load();
    } else {
        console.log('Your browser is not letting some posts be rendered. Consider disabling some extensions.')
    }

    return (
        <div className="container">
            <h1 className="d-flex justify-content-center"><Link to="/">boards</Link>{'/' + board + '/'}<Link to={ "/board/" + board + "/related" }>related</Link></h1>
            {
                posts.map((post, key) => {
                    if(posts.length === key + 1) {
                        return <div className="d-flex justify-content-center" ref={lastPostElementRef} key={key}><SocialboardPost post={post} key={key} /></div>
                    } else {
                        return <div className="d-flex justify-content-center" key={key}><SocialboardPost post={post} key={key} /></div>
                    }
                })
            }
            <h1 className="d-flex justify-content-center">{loading && 'Fetching Saved Posts...'}</h1>
            <h1 className="d-flex justify-content-center">{error && 'Error Fetching Saved Posts'}</h1>
        </div>
    )
}

export default Board
